import React, { Component } from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./page.module.css"

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO
          title={StaticPage.yoast_meta.yoast_wpseo_title}
          description={StaticPage.yoast_meta.yoast_wpseo_metadesc}
        />
        <Grid>
          <Row>
            <Col>
              <h1 className={style.pageTitle}>{StaticPage.title}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
            </Col>
          </Row>
        </Grid>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
